.action-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
}

.action {
  border-radius: 5px;
  box-shadow: 0 0.9px 1.7px rgba(0, 0, 0, 0.35);
  height: 75px;
  width: 250px;
  margin: 0 8px 16px;
  padding: 16px;
  transition: all 0.2s ease-in-out;
  line-height: 24px;

  font-weight: bold;
  vertical-align: middle;
  font-family: "bubblegum";
  font-size: large;
}

.action-container .action:not(:last-child) {
  margin-right: 0;
}

.action.action-small {
  height: 16px;
  width: 168px;
}

.action-container .action:not(.highlight-action) {
  cursor: pointer;
}

.action-container .action:not(.highlight-action):hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
}

.action-container .action:not(.highlight-action):hover {
  fill: rgb(105, 103, 103);
}

.action.highlight-action {
  background-color: #5c6670;
  color: white;
  font-weight: 600;
  border: none;
  width: auto;
  min-width: 30%;
  position: relative;
}

.action.action.highlight-action span {
  margin-left: 60px;
}


.actionIcon {
  font-size: 50px;
  // color: rgba(58, 156, 18, 0.733);
}

/* Responsive Styles */
@media screen and (max-width: 767px) {

  .action-container > *:not(.circle-link) ,
  .terminal {
    width: 100%;
  }

  .action:not(.highlight-action) {
    height: 16px;
    margin: 8px 0;
  }

  .action.highlight-action span {
    margin-left: 72px;
  }

  .actionIcon {
    font-size: 23px;
  }
}

