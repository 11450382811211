@use '@angular/material' as mat;
@import './style/badge';
@import './style/variables';
@import './style/transitions';
@import './style/breakpoints';
@import './style/rtl';
@import './style/colors';
@import './style/badge';
@import './style/scrollbar';

@import './style/reboot';
@import './style/misc';

.matero-container-wrap,
.matero-container {
  height: 100%;
}

.matero-content {
  position: relative;
  padding: $gutter;
}

.matero-header {
  position: relative;
  z-index: 201;
  display: block;

  .matero-toolbar {
    padding: 0 8px;
  }

  // Layout control
  .matero-header-fixed & {
    position: sticky;
    top: 0;
  }
}

.matero-sidenav {
  position: absolute;
  overflow-x: hidden;
  transition: swift-ease-out(width); // Only set width property

  @include mat.elevation(2);

  &.mat-drawer-side {
    border-width: 0;
  }
}

// Layout control
.matero-header-above {
  .matero-container {
    height: calc(100% - #{$toolbar-height-desktop}) !important;
  }

  .matero-sidebar-main {
    height: 100% !important;
  }
}

// Layout control
.matero-sidenav-collapsed,
.matero-sidenav-collapsed-fix {
  .matero-sidenav {
    width: $sidenav-collapsed-width;

    .menu-name,
    .menu-label,
    .menu-badge,
    .menu-caret,
    .matero-user-panel-name,
    .matero-user-panel-email,
    .matero-user-panel-icons {
      opacity: 0;
      transition: swift-ease-out(opacity);
    }

    .user-data-panel {
      height: 0;
      transition: swift-ease-out(height);
    }

    .matero-user-panel-avatar {
      transform: scale(.5);
    }

    &:hover {
      width: $sidenav-width;

      .menu-name,
      .menu-label,
      .menu-badge,
      .menu-caret,
      .matero-user-panel-name,
      .matero-user-panel-email,
      .matero-user-panel-icons {
        opacity: 1;
        transition: swift-ease-in(opacity);
      }

      .user-data-panel {
        height: 70px;
        transition: swift-ease-out(height);
      }

      .matero-user-panel-avatar {
        transform: scale(1);
      }
    }
  }
}

// Layout control
.matero-sidenav-collapsed {
  .matero-content-wrap {
    margin-left: $sidenav-collapsed-width !important;

    [dir='rtl'] & {
      margin-left: auto !important;
      margin-right: $sidenav-collapsed-width !important;
    }
  }

  &[dir='rtl'] .matero-content-wrap {
    margin-left: auto !important;
    margin-right: $sidenav-collapsed-width !important;
  }
}

// Layout control
.matero-navbar-top {
  .matero-topmenu {
    top: 0;
  }

  .matero-branding {
    margin-left: 16px;

    [dir='rtl'] & {
      margin-left: auto;
      margin-right: 16px;
    }
  }
}

// Layout control
.matero-header-fixed {
  .matero-topmenu {
    top: $topmenu-sticky-position-desktop;

    @include bp-lt(small) {
      & {
        top: $topmenu-sticky-position-mobile;
      }
    }
  }
}

// Fix the init content width
.matero-content-width-fix {
  .matero-content-wrap {
    margin-left: $sidenav-width !important;

    [dir='rtl'] & {
      margin-left: auto !important;
      margin-right: $sidenav-width !important;
    }
  }
}

// Colorful // Comentado para Permitir Toolbar Colorida
// .matero-header-white {
//   .matero-toolbar,
//   .matero-topmenu {
//     background-color: white;
//   }
// }
