@use '@angular/material' as mat;
@import '@angular/material/theming';
@include mat.core();

@import 'app/layout/layout';
@import 'styles/custom';
@import 'styles/grid';
@import 'styles/helpers';
@import 'styles/plugins';
@import 'styles/modMatero/actions';

@font-face {
  font-family: bubblegum;
  src: url(assets/fonts/BubblegumSans-Regular.otf) format("opentype");
}

.efeito {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 0;
  svg {
    background-color: transparent !important;
  }
}

.authHomeReturn {
  position: absolute;
  left: 8px;
  top: 8px;
  background-color: transparent !important;
  .logo {
    height: 50px;
  }

}
.authHomeReturnButton {
  color: white !important;
}

.snack {
  min-height: 48px;
  padding: 14px 16px;
  border-radius: 4px;
  width: 60px !important;
  box-shadow: 0 0 10px whitesmoke !important;
  @include mat.elevation(15);
  &:hover {
    box-shadow: 0 0 20px whitesmoke !important;
    @include mat.elevation(15);
  }
}

.toast-success {
  background-color: green !important;
  color: whitesmoke !important;
}

.toast-info {
  background-color: lightblue !important;
  color: black !important;
}

.toast-warning {
  background-color: orange !important;
  color: whitesmoke !important;
}

.toast-error {
  background-color: red !important;
  color: white !important;
}

// .toast-custom {
//   background-color: mat-color($primary, A100);
//   color: mat-color($primary, A100-contrast);
// }

.showOverlayy {
  position: fixed; /* Sit on top of the page content */
  width: 100vw; /* Full width (cover the whole page) */
  height: 100vh; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Black background with opacity */
  z-index: 5000; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -ms-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// SPINNER /////////////////////////////////////////////////////////////////////////////////////
.app-loading {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.app-loading .spinner {
  height: 200px;
  width: 200px;
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.app-loading .spinner .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: #ddd;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
// SPINNER /////////////////////////////////////////////////////////////////////////////////////

.diolago-public .mat-dialog-container {
  // overflow: auto;
  border-radius: 10px;
}


// Classes///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.none {filter:none;}
.blur {filter:blur(2.5px);}
.brightness {filter:brightness(200%);}
.contrast {filter:contrast(200%);}
.drop-shadow {filter:drop-shadow(8px 8px 10px gray);}
.grayscale {filter:grayscale(100%);}
.hue-rotate {filter:hue-rotate(90deg);}
.invert {filter:invert(100%);}
.opacity {filter:opacity(45%);}
.saturate {filter:saturate(2);}
.sepia {filter:sepia(100%);}
.contrast-brightness {filter:contrast(200%) brightness(150%);}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////