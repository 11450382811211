// Material color helpers including text color and background color

@mixin generate-color($prefix, $property) {
  @each $name, $value in $mat-colors {
    // If the value is a map, continue to each
    @if type-of($value) == 'map' {
      @each $hue, $color in $value {
        @if ($hue != 'contrast') {
          .#{$prefix + '-' + $name + '-' + $hue} {
            #{$property}: $color !important;
          }
        }
        // alias
        @if ($name == 'gray') {
          .#{$prefix + '-grey-' + $hue} {
            @extend .#{$prefix + '-gray-' + $hue} !optional;
          }
        }

        @if ($name == 'blue-gray') {
          .#{$prefix + '-blue-grey-' + $hue} {
            @extend .#{$prefix + '-blue-gray-' + $hue} !optional;
          }
        }
      }
    }

    @if type-of($value) == 'color' {
      .#{$prefix + '-' + $name} {
        #{$property}: $value !important;
      }
    }
  }
}

@include generate-color('text', 'color');

@include generate-color('bg', 'background-color');
